<i18n>
ru:
  ajaxErrorCode3: Ошибка ввода CAPTCHA
  ajaxErrorUnknown: >-
    Произошла ошибка, Ваш отзыв не отправлен. Попробуйте снова или свяжитесь с
    нами.
  comment: Комментарий
  date: Дата
  email: Эл. почта
  name: Имя
  personCount: Количество персон
  personalDataMessage: 'Предоставляя свои персональные данные, Вы соглашаетесь с '
  personalDataMessageLink: условиями обработки персональных данных
  phoneNumber: Телефон
  send: Отправить
  tableReservation: Где бронировать столик
  textOverSendButton: ''
  time: Время
  title: Забронировать столик
ua:
  ajaxErrorCode3: Помилка введення CAPTCHA
  ajaxErrorUnknown: >-
    Сталася помилка, ваш відгук не відправлений. Спробуйте знову або зв’яжіться
    з нами.
  comment: Коментар
  date: Дата
  email: Ел. пошта
  name: Ім’я
  personCount: Кількість персон
  personalDataMessage: 'Надаючи свої персональні дані, ви погоджуєтеся з '
  personalDataMessageLink: умовами обробки персональних даних
  phoneNumber: Телефон
  send: Відправивши
  tableReservation: Де бронювати столик
  textOverSendButton: ''
  time: Час
  title: Забронювати столик
us:
  ajaxErrorCode3: CAPTCHA error
  ajaxErrorUnknown: >-
    An error has occurred, your feedback has not been sent. Try again or contact
    us.
  comment: Comment
  date: Date
  email: E-mail
  name: Name
  personCount: Person count
  personalDataMessage: 'By submitting your personal data, you agree to '
  personalDataMessageLink: the terms of personal data processing
  phoneNumber: Phone number
  send: Send
  tableReservation: Where to book a table
  textOverSendButton: ''
  time: Time
  title: Book a table
</i18n>

<template>
  <div
    v-if="pageStore.Restaurants.state === 'success'"
    id="v-table-reservation-form"
    class="v-table-reservation-form"
  >
    <div class="v-row">
      <div
        class="v-col-12 v-table-reservation-form--title v-title v-mb-md"
        v-html="translate('tableReservationForm.title')"
      />
      <div
        v-if="$slots.item"
        class="v-col-12 v-mb-md"
      >
        <slot name="item" />
      </div>
      <div class="v-col-12 v-col-md-6">
        <v-input
          class-name="v-arora-input"
          required
          v-form-validator="{
            Form: validatorForm,
            Value: tableReservationFields.name,
            Required: true,
            Validations: ['length'],
            MaxLength: 255
          }"
          :label="translate('tableReservationForm.name')"
          v-model:text="tableReservationFields.name"
        />
      </div>
      <div
        v-if="appConfig.VueSettingsPreRun.HallsTableReservationPersonCountEnabled"
        class="v-col-12 v-col-md-6"
      >
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: tableReservationFields.wishfulTerminalName,
            Required: appConfig.VueSettingsPreRun.HallsTableReservationPersonCountRequired,
            Validations: ['digits', 'length'],
            MaxLength: 255
          }"
          :label="translate('tableReservationForm.personCount')"
          type="number"
          v-model:text="tableReservationFields.wishfulTerminalName"
        />
      </div>
      <div class="v-col-12 v-col-md-6">
        <common-phone-input
          required
          :label="translate('tableReservationForm.phoneNumber')"
          :validator-form="validatorForm"
          v-model:phone="tableReservationFields.phone"
        />
      </div>

      <div
        v-if="appConfig.VueSettingsPreRun.HallsTableReservationEmailEnabled"
        class="v-col-12 v-col-md-6"
      >
        <v-email-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: tableReservationFields.email,
            Required: appConfig.VueSettingsPreRun.HallsTableReservationEmailRequired,
            Validations: ['email', 'length'],
            MaxLength: 255
          }"
          :label="translate('tableReservationForm.email')"
          v-model:text="tableReservationFields.email"
        />
      </div>
      <div class="v-col-12 v-col-md-6">
        <arora-date-input
          required
          v-form-validator="{
            Form: validatorForm,
            Value: tableReservationFields.bday,
            Required: true,
            Validations: ['date']
          }"
          :label="translate('tableReservationForm.date')"
          v-model:text="tableReservationFields.bday"
        />
      </div>
      <div class="v-col-12 v-col-md-6">
        <v-input
          class-name="v-arora-input"
          required
          v-form-validator="{
            Form: validatorForm,
            Value: tableReservationFields.citizenship,
            Required: true,
            Validations: ['length']
          }"
          :label="translate('tableReservationForm.time')"
          v-model:text="tableReservationFields.citizenship"
        />
      </div>
      <div
        v-if="pageStore.RestaurantsSorted.length > 1"
        class="v-col-12 v-col-md-6"
        v-form-validator="{
          Form: validatorForm,
          Value: tableReservationFields.vacancyId,
          Required: true,
          Validations: ['select']
        }"
      >
        <arora-select
          required
          :label="translate('tableReservationForm.tableReservation')"
          :options="restaurants.map((item) => item.ID)"
          v-model:selected="pageStore.SelectedTableReservationId"
        >
          <template #index="options: { index: number }">
            <option
              :value="restaurants[options.index].ID"
              v-html="sanitize(restaurants[options.index].Title)"
            />
          </template>
        </arora-select>
      </div>

      <div
        v-if="appConfig.VueSettingsPreRun.HallsTableReservationCommentEnabled"
        class="v-col-12 v-col-md-6"
      >
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: tableReservationFields.about,
            Required: appConfig.VueSettingsPreRun.HallsTableReservationCommentRequired,
            Validations: ['length']
          }"
          :label="translate('tableReservationForm.comment')"
          v-model:text="tableReservationFields.about"
        />
      </div>

      <div class="v-col-12">
        <lazy-common-captcha />
      </div>

      <div
        v-if="!stringIsNullOrWhitespace(translate('tableReservationForm.textOverSendButton'))"
        class="v-col-12 v-d-flex v-justify-content-center v-mb-xs"
        :class="[
          !appConfig.VueSettingsPreRun.EnablePersonalDataWarning && !error ? 'v-mb-lg' : 'v-mb-xs'
        ]"
        v-html="translate('tableReservationForm.textOverSendButton')"
      />
      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <div
          v-if="error"
          class="v-col-12 v-d-flex v-justify-content-center v-error-color"
          :class="[appConfig.VueSettingsPreRun.EnablePersonalDataWarning ? 'v-mb-xs' : 'v-mb-lg']"
          v-html="error"
        />
      </transition>

      <div
        v-if="appConfig.VueSettingsPreRun.EnablePersonalDataWarning"
        class="v-col-12 v-d-flex v-flex-row v-mb-lg"
      >
        <common-legal-check
          check-type="table-reservation"
          v-model:checked="dataConsent"
        />
      </div>

      <div class="v-col-12 v-d-flex v-justify-content-end">
        <arora-button
          :disabled="
            lockButton ||
            (appConfig.VueSettingsPreRun.EnablePersonalDataWarning && !dataConsent) ||
            Guid.IsNullOrEmpty(tableReservationFields.vacancyId)
          "
          :label="translate('tableReservationForm.send')"
          @click="async () => await send()"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Hall, VacancyPayload } from '~types/pageStore'

import { useCommon, useValidationStore, VEmailInput, vFormValidator, VInput } from '@arora/common'

import { getActivePinia } from 'pinia'
import { AuthOperationsErrorCode, Guid } from '~api/consts'

const restaurantStore = useRestaurantStore()
const accountStore = useAccountStore()
const pageStore = usePageStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const { clean, sanitize, translate } = useI18nSanitized()
const { eventEmit } = useEmitter()
const { fromPopup } = useInstance()
const { stringIsNullOrWhitespace } = useCommon()
const appConfig = useAppConfig()

const restaurants = computed<Hall[]>(
  () => pageStore.RestaurantsSorted?.filter((item) => item.ReservationEnabled) ?? []
)
const dataConsent = ref<boolean>(false)

onMounted(async () => {
  if (import.meta.client) await pageStore.initRestaurants()

  if (accountStore.isLoggedIn && accountStore.Profile.data) {
    const profileData = accountStore.Profile.data

    tableReservationFields.value.name = profileData.Name
    tableReservationFields.value.email = profileData.Email
    tableReservationFields.value.phone = profileData.Phone
  }
})

const validatorForm = 'tableReservation'

const tableReservationFields = ref<VacancyPayload>({
  about: undefined as string | undefined,
  attachment: null as File | null,
  bday: null as string | null,
  citizenship: null as string | null,
  email: undefined as string | undefined,
  name: undefined as string | undefined,
  passedV3: true,
  phone: undefined as string | undefined,
  recaptcha: null as string | null,
  smartcaptcha: null as string | null,
  vacancyId: pageStore.SelectedTableReservationId ?? Guid.Empty,
  wishfulTerminalName: undefined as string | undefined
})

const error = ref<string | null>(null)
const lockButton = ref<boolean>(false)

async function send(): Promise<void> {
  lockButton.value = true
  error.value = null

  tableReservationFields.value.name = clean(tableReservationFields.value.name)
  tableReservationFields.value.phone = clean(tableReservationFields.value.phone)
  tableReservationFields.value.email = clean(tableReservationFields.value.email)
  tableReservationFields.value.about = clean(tableReservationFields.value.about)
  tableReservationFields.value.wishfulTerminalName = clean(
    tableReservationFields.value.wishfulTerminalName
  )
  tableReservationFields.value.bday = clean(tableReservationFields.value.bday)
  tableReservationFields.value.citizenship = clean(tableReservationFields.value.citizenship)

  tableReservationFields.value = await restaurantStore.validateCaptcha(tableReservationFields.value)

  if (!validationStore.formPassedCheck(validatorForm)) {
    lockButton.value = false

    return
  }
  const code = await pageStore.sendTableReservation(tableReservationFields.value)
  lockButton.value = false

  switch (code) {
    case 0: {
      if (fromPopup.value) {
        popupStore.closePopup()
      }
      await popupStore.showSuccess(translate('tableReservationPopup.successMessage'))

      return
    }
    case AuthOperationsErrorCode.CaptchaWasNotVerified:
    case AuthOperationsErrorCode.CaptchaV3Failed: {
      error.value = translate('tableReservationForm.ajaxErrorCode3')
      eventEmit('v-reset-captcha')
      break
    }
    default: {
      error.value = translate('tableReservationForm.ajaxErrorUnknown')
      eventEmit('v-reset-captcha')
      return
    }
  }
}

watch(
  () => pageStore.SelectedTableReservationId,
  (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      tableReservationFields.value.vacancyId = newValue
    }
  }
)
</script>

<style lang="scss">
@use 'assets/variables';

.v-table-reservation-form {
  &--title {
    font-size: variables.$TextSizeH2;
    font-weight: 600;
  }
}
</style>
